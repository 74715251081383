<template>
  <el-container>
    <el-header class="header" height="2.70833vw">
      <div class="header-left">
        <div class="header-title">
          <div class="header-title-icon" />
          从图层发布服务
        </div>
        <!-- <div class="header-btn">从图层发布服务</div> -->
      </div>
    </el-header>
    <el-main class="main">
      <el-form class="form" label-position="top">
        <el-form-item label="选择图层">
          <el-input v-model="layerName" disabled> </el-input>
        </el-form-item>
        <el-form-item label="服务名称">
          <el-input
            v-model="serviceName"
            maxlength="20"
            show-word-limit
            placeholder="请输入服务名称"
          >
            <!-- <template slot="prefix">服务名称：</template> -->
          </el-input>
        </el-form-item>
        <el-form-item label="发布服务类型：">
          <el-radio-group v-model="type">
            <el-radio-button
              v-for="item in serviceTypes"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item class="bottom-btn">
          <el-button type="primary" :disabled="disabled" @click="releaseService"
            >发布服务</el-button
          >
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'ReleaseServiceFromLayer',
  data() {
    return {
      layerName: '',
      serviceName: '',
      type: 1,
      serviceTypes: [
        { label: 'WMS', value: 1 }
        // { label: 'WMTS', value: 2 },
        // { label: 'WFS', value: 3 }
      ],
      layerId: '',
      couponId: ''
    };
  },
  computed: {
    disabled() {
      return !(this.serviceName && this.type);
    }
  },
  created() {
    const { layerName, layerId, couponId } = this.$route.params;
    this.layerName = layerName;
    this.serviceName = layerName;
    this.layerId = layerId;
    this.couponId = couponId;
  },
  methods: {
    releaseService() {
      if (!this.serviceName) {
        return this.$message.warning('请输入服务名称');
      }

      const { serviceName: name, layerId, couponId, type } = this;
      const params = {
        name,
        type,
        layerId,
        couponId
      };
      this.$service.giss.servicePublish(params).then(res => {
        const { status } = res;
        if (status === 200) {
          // 跳转发布成功页面
          this.$router.push('/workbench/releaseService/success');
        }
      });
    }
  }
};
</script>
